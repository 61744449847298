import * as React from 'react';
import { LayoutProvider } from '../components/layout';
import { PostDetail } from '../components/sections';
import IPage from '../@types/IPage';
import { IAllBlogs } from '../pages/blog';
import { CenteredContainer, ResponsiveContainer } from '../atoms/Containers';

const BlogArticle = (props: IPage<IAllBlogs>) => {
  const data = props.pageContext;

  return (
    <LayoutProvider seo>
      <CenteredContainer>
        <ResponsiveContainer flexDirection='column'>
          <PostDetail
            title={data.title}
            mainMedia={data.mainMedia}
            blogCategory={data.blogCategory.name}
            bodyText={data.bodyText}
            blogEntries={data.blogEntries}
          />
        </ResponsiveContainer>
      </CenteredContainer>
    </LayoutProvider>
  );
};

export default BlogArticle;
